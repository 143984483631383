.btn-secondary {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-secondary:hover {
  color: var(--background-primary) !important;
  background-color: var(--primary-color);
}

.btn-danger:hover {
  color: var(--background-primary) !important;
  background-color: var(--secondary-color-red);
}

.btn-warning {
  background-color: var(--status-text-warning);
}

.btn-warning:hover {
  background-color: var(--status-text-warning-whiter) !important;
}

.btn-tertiary-filled {
  color: var(--background-primary);
  background-color: var(--secondary-color-green);
}

.btn-tertiary-filled:hover {
  background-color: var(--secondary-color-green-hover) !important;
}

.btn-tertiary-outlined {
  color: var(--secondary-color-green);
  border-color: var(--secondary-color-green);
}

.btn-tertiary-outlined:hover {
  color: var(--background-primary) !important;
  background-color: var(--secondary-color-green) !important;
}

.button-text > span:nth-child(2) {
  padding-right: 10px;
}
