.sigCanvasClassName {
  border: 1px solid black;
  width: 100%;
  height: 100%;
}
/* .firma-modal {
  margin: 0 auto;
  height: 300px;
} */

.img-firma {
  border: 1px solid black;
  border-radius: 10px;
  width: 220px;
  min-height: 100px;
  margin: 0 auto;
  margin-bottom: 0.5rem;
}

@media only screen and (max-height: 425px) and (orientation: landscape) {
  .firma-modal-wrapper .ant-modal-content,
  .firma-modal-wrapper .firma-modal {
    height: 100vh !important;
    width: 96vw !important;
    margin: 0 !important;
    top: 0 !important;
  }
}
