.cards-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; 
    gap: 20px; 
  }
  
  .card_module {
    min-width: 350px; 
    background: var(--primary-color) !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.654);
    padding: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.75s ease-in-out;
  }
  
  .card_module:hover {
    background: var(--background-primary) !important;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.654);
  }
  
  .card_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    border: 7px solid var(--background-primary) !important;
    transition: all .5s ease-out;
    color: var(--background-primary) !important;
  }
  
  .card_data {
    color: var(--background-primary) !important;
    transition: all .5s;
  }

  .card_name {
    margin-left: -12px;
  }

  .card_module:hover > .card_data { 
    color: var(--primary-color) !important;
    transform: translateX(-45%) scale(1.1);
  }

  .card_module:hover > .card_name { 
    margin-left: 0px;
  }

  
  .card_module:hover > .card_data p{
    display: none;
  }
  
  .card_module:hover > .card_logo {
    color: var(--primary-color) !important;
    border: 7px solid var(--primary-color) !important;
    border-radius: 50%;
    transform: translateX(270%) scale(1.8);
  }
  