@media print {
  body *:not(#my-section):not(#my-section *) {
    visibility: visible;
  }
  #my-section {
    position: absolute;
    top: 0;
    left: 0;
  }
  .ant-layout-sider {
    display: none;
  }
  .ant-layout {
    margin-left: 0 !important;
    background-color: #fff !important;
  }
  .ant-layout-header {
    display: none !important;
  }
  .ant-btn {
    display: none !important;
  }
  .ant-layout-footer {
    display: none !important;
  }
  .anticon-arrow-left {
    display: none;
  }
}
