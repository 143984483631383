.card-body {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: var(--background-primary);
  padding: 2rem;
}

@media screen and (max-width: 425px) {
  .card-body {
    padding: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .card-body {
    text-align: center !important;
  }
  .card-body .ant-form-item-label {
    text-align: center !important;
  }
}
