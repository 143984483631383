.pagina-login {
  padding: 1.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-login {
  max-width: 600px;
}

.contenedor-logos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.logo-bhtrade,
.logo-trademarket {
  width: 90px;
  height: auto;
}

.logo-promolife {
  width: 160px;
  height: auto;
}

.logo-promodreams {
  width: 60px;
  height: auto;
}

.wrapper-form-login {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 30px;
}

.wrapper-logo-bpms {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logobpms {
  width: 182px;
}

.textcorreo,
.textcontra {
  font-size: 24px;
  font-weight: 600;
}

.cajacorreo {
  height: 50px;
  border-radius: 10px;
}

.cajacontraseña {
  height: 50px;
  border-radius: 10px;
}

.conten-btn-iniciar-sesion {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .logo-bhtrade,
  .logo-promodreams,
  .logo-trademarket,
  .logo-promolife {
    max-width: 80px;
  }
  .logo-promolife {
    max-width: 130px;
  }
}
