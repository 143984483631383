@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

:root {
  --primary-color: #1677ff;
  --secondary-color-green: #52c41a;
  --secondary-color-green-hover: #4aa01f;
  --secondary-color-red: #ff4d4f;

  --background-sidebar: #161618;
  --background-primary: #ffffff;
  --background-secondary: #f4f9ff;
  --background-table-row: #f6f6f6;

  --status-background-info: #e6f4ff;
  --status-background-success: #f6ffed;
  --status-background-warning: #fffbe6;
  --status-background-error: #fff1f0;
  --status-text-info: #0958d9;
  --status-text-success: #389e0d;
  --status-text-warning: #d48806;
  --status-text-warning-whiter: #e0a033;
  --status-color-processFinish: #ffdc18;
  --status-color-entregaCliente: #ED9121;
  --status-text-error: #cf1322;
  --table-header: #0958d9;
}

*,
*::before,
*::after {
  margin: 0;
  /* Remove default margin */
  padding: 0;
  /* Remove default padding */
  box-sizing: border-box;
  /* Remove default box-sizing */
}

/* Typographic tweaks! */
body,
html {
  scroll-behavior: smooth;
  /* Smooth scrolling */
  min-height: 100vh;
  /* Allow percentage-based heights */
  -webkit-font-smoothing: antialiased;
  /* Improve text rendering */
  -moz-osx-font-smoothing: grayscale;
  /* Improve text rendering */
  font-size: 16px;
  font-family: 'Montserrat', sans-serif !important;
  overflow-x: hidden;
}

[class^='ant-spin'] {
  font-family: 'Montserrat', sans-serif !important;
}

/*typography*/
h1,
.title {
  font-weight: 600;
  font-size: 2rem;
  text-transform: uppercase;
}

h2,
.subtitle {
  font-weight: 500;
  font-size: 1.5rem;
  text-transform: capitalize;
}

p,
span,
a {
  font-size: 1rem;
}

.text-white {
  color: var(--background-primary);
}

.body-fixed {
  overflow: hidden;
  /* Evita que el cuerpo se desplace */
  position: fixed;
  /* Fija el cuerpo en su lugar */
  width: 100%;
  /* Asegura que ocupe toda la pantalla */
  height: 100%;
  /* Asegura que ocupe toda la pantalla */
}

.placeholder {
  font-weight: 400;
  font-size: 1rem;
  color: #767676;
}

.button-text {
  height: auto !important;
}

.button-text>span {
  font-weight: 500;
  font-size: 1rem;
}

/* .ant-descriptions-item-label.description-header-text {
  font-weight: 500;
} */

/* Improve media defaults */
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/* Remove built-in form typography styles */
input,
button,
textarea,
select {
  font: inherit;
}

/* Avoid text overflows */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  hyphens: auto;
}

/* Create a root stacking context */
#root,
#__next {
  isolation: isolate;
}

/*  */
/*  */
/* DONE WITH RESET CSS */
/*  */
/*  */

/* GLOBAL STYLES */

/* set root elemet to 100% */
#root,
.ant-layout {
  min-height: 100vh;
}

/* Styles for layout */
.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.site-layout-background.ant-layout-content {
  margin: 70px 16px 24px 16px;
  padding: 24px;
  min-height: 100vh;
}

.site-layout .site-layout-background {
  background: #fff;
}

.section-user-info {
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-user-info>.ant-avatar {
  min-width: 50px;
}

.section-user-info-name,
.section-user-info-role {
  color: var(--background-primary);
  margin-left: 0.5rem;
  line-height: 1.2rem;
  font-size: 0.9rem;
}

.section-user-info-role {
  font-size: 0.8rem;
  text-transform: capitalize;
}

/* endof Styles for layout */

.wrapper-general-status-bar {
  overflow-x: scroll;
}

.ant-table-content {
  overflow-x: scroll;
}

.font-weight-bold {
  font-weight: bolder;
}

.font-weight-500 {
  font-weight: 500;
}

/* TABLE HEADERS */

.ant-table-column-title {
  font-size: 0.9rem;
}

.ant-table-wrapper .ant-table-thead>tr>th {
  background-color: var(--table-header) !important;
  color: var(--background-primary);
  text-align: center;
  justify-content: center;
}

.ant-table-cell .ant-table-wrapper .ant-table-thead>tr>th {
  background-color: #d3d3d3 !important;
  color: black;
}

.ant-table-wrapper .ant-table-filter-trigger>span>svg {
  color: var(--background-primary);
}

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: rgb(255 255 255 / 85%);
}

.ant-table-cell {
  text-align: center;
}

.ant-table-wrapper .ant-table-column-sorter {
  color: var(--background-primary);
  width: 12px;
}

/* Search bar */
.button-close-search-filter-table {
  width: 100%;
  height: 30px;
  display: block;
  text-align: right;
  color: #7d7d7d;
}

.button-close-search-filter-table:hover {
  color: var(--secondary-color-red) !important;
}

/* endof Search bar */

/* icon of search bar */
.ant-table-wrapper .ant-table-filter-trigger {
  width: 25px;
}

/* endof icon of search bar */

.ant-tooltip .ant-tooltip-inner {
  text-align: center;
}

/* END OF TABLE HEADERS */


/* Estilos globales para los botones de SweetAlert */
.swal2-confirm {
  background-color: var(--primary-color) !important;
  /* Cambia el color de fondo del botón de confirmación */
}

.swal2-cancel {
  background-color: var(--secondary-color-red) !important;
  /* Cambia el color de fondo del botón de cancelación */
}


.circulo_step_process {
	width: 24px;
	height: 24px;
	border-radius: 24px;
	background-color: #1890ff;
  margin-inline: 0 8px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.circulo_step_error {
	width: 24px;
	height: 24px;
	border-radius: 24px;
	background-color: var(--secondary-color-red);
  margin-inline: 0 8px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.circulo_step_success {
	width: 24px;
	height: 24px;
	border-radius: 24px;
	background-color: var(--secondary-color-green);
  margin-inline: 0 8px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.circulo_step_succesProcess {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: var(--status-color-processFinish);
  margin-inline: 0 8px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.circulo_step_entregaCliente {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: var(--status-color-entregaCliente);
  margin-inline: 0 8px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}


.circulo_step_number {
	font-family: sans-serif;
	color: white;
	font-size: 16px;
}

/* Estilos globales para boton exportar tabla excel */
.download-table-xls-button {
  background-color: var(--primary-color);
  font-family: 'montserrat', sans-serif;
  color: #fff;
  border: none;
  padding: 7px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.download-table-xls-button:hover {
  background-color: #4096ff;
}

.centered-input .ant-input-number-input {
  text-align: center;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-4 {
  margin-left: 1.5rem;
}

.ml-5 {
  margin-left: 3rem;
}

.ml-6 {
  margin-left: 6rem;
}

.ml-7 {
  margin-left: 12rem;
}

.ml-8 {
  margin-left: 24rem;
}

.ml-9 {
  margin-left: 48rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.mr-5 {
  margin-right: 3rem;
}

.mr-6 {
  margin-right: 6rem;
}

.mr-7 {
  margin-right: 12rem;
}

.mr-8 {
  margin-right: 24rem;
}

.mr-9 {
  margin-right: 48rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.justify-content-end {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-end;
}

.title-content-between {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-center {
  justify-content: center;
}

.align-self-center {
  align-self: center;
}

.align-items-center {
  align-items: center;
}

.width-auto {
  width: auto;
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}

.width-35 {
  width: 35%;
}

.rounded {
  border-radius: 10px;
}

.text-align-center {
  text-align: center;
}

.text-align-jusify {
  text-align: justify;
}

.div-margin-image-1 {
  display: inline-block;
  width: 102px;
  height: 102px;
  margin-inline: 0 8px;
  vertical-align: top;
}

.div-margin-image-2 {
  width: 100px;
  height: 100%;
  margin: 0;
  position: relative;
  padding: 8px;
  border: 1px solid #161618;
  border-radius: 8px;
  text-align: center;
}

.image-style {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 60px;
  text-align: center;
  flex: none;
}




/* Cambiar el tamaño del titulo para los madales*/


@media screen and (max-width: 992px) {
  .ant-modal .ant-modal-title {
    font-size: 24px;
  }
}

@media screen and (min-width: 992px) {
  .ant-modal .ant-modal-title {
    font-size: 32px;
  }
}

/* Cambiar diseño de titulo de tablas*/
.ant-table-title {
  font-weight: 600;
  font-size: 24px;
}

.container {
  width: 100%;
  margin: auto;
}

@media screen and (min-width: 1600px) {
  .container {
    max-width: 1600px;
  }
}

/* Media query for mobile */
@media only screen and (max-width: 768px) {
  .site-layout-background.ant-layout-content {
    margin: 70px 12px 24px 12px;
    padding: 15px;
  }

  h1 {
    font-size: 1.6rem;
  }

  h2 {
    font-size: 1.3rem;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem;
  }

  p,
  span,
  a {
    font-size: 0.9rem;
  }
}

.arrow-size {
  font-size: 2rem;
}

.font-weight-800 {
  font-weight: 800;
}

.bold-form .ant-form-item-label>label {
  font-weight: bold;
}